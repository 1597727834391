import React from "react";

const Redirect = ({ message }) => {
  return (
    <div className="redirect">
      <div className="reg2">
        {message}
        {/* Kindly check your registered webmail inbox/spam/junk */}
      </div>
    </div>
  );
};

export default Redirect;
// 1662062021
// Access
